import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import LandingPage from './components/LandingPage';
import './index.css'; // Adjust the path as necessary
import Footer from './components/Footer';
import DropdownTextBox from './components/DropdownTextBox';
import Essays from './components/Essays'; 
import ReactMarkdown from 'react-markdown';
import SimplePDFViewer from './components/SimplePDFViewer';
import CannotOrShouldNotAutomatePDF from "./pictures/PDFs/99_Cannot_or_Should_Not_Automa.pdf";
import MyPaperPDF from "./pictures/PDFs/1-s2.0-S0378437124004618-main.pdf";

import ErrorBoundary from './components/ErrorBoundary';
import PaperScreenshot from './PaperScreenshotWebsite.png'; // Adjust the path if necessary
import EMWebsite from './219shots_so Mac Electomate home.png'; // Adjust the path if necessary
import ScreenshotDiplomatica from './ScreenshotDiplomatica.png'; // Adjust the path if necessary
import ScreenshotPAPER from './pictures/ScreenshotPAPER.png'; // Adjust the path if necessary
import ScreenshotSO from './pictures/560shots_so.png'; // Adjust the path if necessary
import ScreenshotRefusals from './pictures/Screenshot refusals.png'; // Adjust the path if necessary

import YouTubeEmbed from './components/YouTubeEmbed';
import './App.css';
import axios from 'axios'; // Added axios import
import EM_PDF from "./pictures/PDFs/Electomate.pdf";



const PDFViewer = lazy(() => import('tailwind-pdf-viewer'));

const App = () => {
  const [openIndexEssays, setOpenIndexEssays] = useState(null);
  const [openIndexProjects, setOpenIndexProjects] = useState(null);
  const [openIndexProjectIdeas, setOpenIndexProjectIdeas] = useState(null);
  const [openIndexListVideosPhotos, setOpenIndexListVideosPhotos] = useState(null);
  const textareaRef = useRef(null); // Create a ref for the textarea

  // Add these state variables
  const [showPopup, setShowPopup] = useState(false);
  const [password, setPassword] = useState('');
  const [showPrivateContent, setShowPrivateContent] = useState(false);

  // Handler functions
  const handleImageClick = () => {
    setShowPopup(true);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    if (password === '100300') {
      setShowPrivateContent(true);
      setShowPopup(false);
      setPassword('');
    } else {
      alert('Incorrect password');
      setPassword('');
    }
  };

  const handleToggleEssays = (index) => {
    setOpenIndexEssays(openIndexEssays === index ? null : index);
  };

  const handleToggleProjects = (index) => {
    setOpenIndexProjects(openIndexProjects === index ? null : index);
  };

  const handleToggleProjectIdeas = (index) => {
    setOpenIndexProjectIdeas(openIndexProjectIdeas === index ? null : index);
  };

  const handleToggleListVideosPhotos = (index) => {
    setOpenIndexListVideosPhotos(openIndexListVideosPhotos === index ? null : index);
  };

  const ListVideosPhotos = [
    {
      id: "pQe3QYj68wg",
      title: "German Zero",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="pQe3QYj68wg" playing={openIndexListVideosPhotos === "pQe3QYj68wg"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "i6GETfyGDQc",
      title: "Weltenende ins Grüne",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="i6GETfyGDQc" playing={openIndexListVideosPhotos === "i6GETfyGDQc"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "-0SljoJPty0",
      title: "TUM Boring Release Movie",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="-0SljoJPty0" playing={openIndexListVideosPhotos === "-0SljoJPty0"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "0wTYMhIAYP0",
      title: "ZuMUN 2024 Aftermovie",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="0wTYMhIAYP0" playing={openIndexListVideosPhotos === "0wTYMhIAYP0"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "6OPsH8PK7xM",
      title: "Polylog - What P vs NP is actually about",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="6OPsH8PK7xM" playing={openIndexListVideosPhotos === "6OPsH8PK7xM"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "B3B9N5DnRjI",
      title: "FC Schalke 04 E Sports",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="B3B9N5DnRjI" playing={openIndexListVideosPhotos === "B3B9N5DnRjI"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "Euv2Y48VYlk",
      title: "Scenario Remix",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="Euv2Y48VYlk" playing={openIndexListVideosPhotos === "Euv2Y48VYlk"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "3rkv7k_kXNw",
      title: "SipTogether",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="3rkv7k_kXNw" playing={openIndexListVideosPhotos === "3rkv7k_kXNw"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "C-bK3T0GZio",
      title: "Phoenix Racing - DTM",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="C-bK3T0GZio" playing={openIndexListVideosPhotos === "C-bK3T0GZio"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "aIVCsj-U-CQ",
      title: "New Zealand Travels Trailer",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="aIVCsj-U-CQ" playing={openIndexListVideosPhotos === "aIVCsj-U-CQ"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "0EcpGuLZYes",
      title: "Music Video - Spasi Se Od Mene",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="0EcpGuLZYes" playing={openIndexListVideosPhotos === "0EcpGuLZYes"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "Sy9f1nO2REs",
      title: "24h Filmfestival",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="Sy9f1nO2REs" playing={openIndexListVideosPhotos === "Sy9f1nO2REs"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "sgvy1GfgaF8",
      title: "Mercedes G Class wagon chase",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="sgvy1GfgaF8" playing={openIndexListVideosPhotos === "sgvy1GfgaF8"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "a7koSW9vg90",
      title: "BTS - Music Video Shoot",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="a7koSW9vg90" playing={openIndexListVideosPhotos === "a7koSW9vg90"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    }
  ];
  



  


  const dropdownContentsEssays = [
    { id: 1, title: "How to Apologize", content: `
      ### The Range of Responses:

      - **Denial and Self-Victimization:**
        - Denial of reality.
        - Inverting situations to make you question your own perception.
        - Self-victimizing: mistakes happen while being a victim yourself, often unaware of your own wrongdoings.
        - Offering excuses.
        - Attacking back.

      - **Minimizing and Justifying:**
        - Making excuses, saying it's not that bad.
        - Claiming the right to their actions.

      - **Silence and Minimal Acknowledgment:**
        - Not saying anything.
        - Just saying "sorry."

      - **Deflecting Responsibility:**
        - Saying "sorry that you feel that way."
        - Apologizing for the other person's understanding of the situation.
        - Saying "sorry that I made you feel that way."

      - **Acknowledging and Taking Responsibility:**
        - Apologizing with full acknowledgment: "Sorry that this happened. It's my fault."
        - Expressing understanding: "I understand this made you feel like that. I guess it's normal that you react this way, and it's not acceptable what I have done."
        - Admitting unintentional harm and recognizing a part of you that acted knowingly.

      - **Understanding and Commitment to Change:**
        - Explaining how your mistake has affected the other person.
        - Asking if your understanding of the impact is correct.
        - Showing genuine validation by understanding and explaining the other's feelings.
        - Making a plan for future behavior to prevent recurrence and protect the other person.
        - Showing through actions rather than just words.

      - **Differentiating Understanding and Change:**
        - Saying you understand vs. explaining how you understand.
        - Saying you'll change or regret vs. actually changing or showing effort to change.
    `},
    { id: 2, title: "Kinds of Influence", content: "" },
    { id: 3, title: "Being in tune or hurting people", content: "" },
    { id: 4, title: "Meaning of Life and Meaning of Love", content: "" },
    { id: 5, title: "About Veganism and Alt Proteins", content: "" },
    { id: 6, title: "How to make alt protein cool", content: `
    ` },
    { id: 7, title: "The position of acknowledgement is a privileged one", content: "Content discussing the privileged position of acknowledgment." },
    { id: 8, title: "Vier feeder tafel: about value of altruism", content: "Content discussing four square graph and the value of altruism." },
    { id: 9, title: "About Sustainability Ethics", content: "Content discussing different ethical fundaments: Biocentirsm, Geocentrism, Anthropocentrism." },
    { id: 10, title: "Societal change aspects", content: "Patterns of my mum, psychological effects and phases of discovering something like this." },
    { id: 11, title: "Evidence based and self reflecting as best and only learning needed for a human? The expectation to my children", content: "Content discussing evidence-based and self-reflecting learning and expectations for children." }
  ];

  const dropdownContentsProjects = [
    { id: 1, title: "Diplomatica", content: (
        <div>
          <p>This project aims to impact on 3 different dimensions:</p>

          <p><strong>Training of EQ & Communication skills</strong></p>
          <p><strong>New Evals for soft skills</strong></p>
          <p><strong>Social divides & Cognitive bias</strong></p>

          <p>This platform aims to introduce a new class of learning. While quantitative assessments, trainings and competitions in technical fields like computer science, mathematics, etc. are broadly available and serve as a metric for economic as well as cognitive value, emotional intelligence, soft skills, wisdom, kindness, and life experience could hardly be measured or practiced in a similar way. While the former has been creating a certain value system, one can assume that having an equal emphasis on the very basic nature of our humanness - emotion, empathy, and communication - would tackle most global problems we face nowadays, individually and collectively.</p>

          <p>This tool tries to help with individual as well as group conflicts and situations that are hard to learn without experience. Learning in these realms so far has been limited by social segregation, therefore the pure chance of encountering specific situations, poor education or existence of role models, the amount of books one could read. None of these are scalable or accessible to everyone nor is it pleasant to encounter opposing views and experience ranges.</p>

          <p>Combining concepts like ELO rankings & puzzles from chess, gamification in form of levels and leaderboards from Gandalf or Duolingo with the advancements in NLP, this tool offers an accessible way to do the same in the social realm. This is LeetCode for human decoding.</p>

          <h2><strong>Summary:</strong></h2>
          <p>A safe space for people to try out new things and take time to reflect without causing harm.</p>
          <p>Practice communication and pick up useful phrases or at least realize which ones are destructive.</p>
          <p>Simulate challenging conversations, such as those involving sensitive topics like rape, depression, and death, without the need to talk to real people risking their or one's own emotional wellbeing.</p>
          <p>Aiming for more peace through self-education with immediate access to gamified practice.</p>

          <h2><strong>Future ideas:</strong></h2>
          <p>SaaS for recruitment interviews for companies or to train their employees in soft skills.</p>
          <p>Machine-readable database for social scientists to understand how people argue and think.</p>
          <p>LeetCode for the social realm, users could gain credentials for a metric not represented yet.</p>
          <p>Simulator for Model United Nations and high lvl. diplomatic discourse</p>
          <img 
            src={ScreenshotDiplomatica} 
            alt="Diplomatica Website" 
            style={{ borderRadius: '15px', marginTop: '10px', width: '50%' }} 
          />
          <a href="https://diplomatica.ai/" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', marginLeft: '5px' }}>
            <span>Check out the website here ➔</span>
          </a>
        </div>
      ), year: "2024", ActiveYN: "(Active)"},
    { id: 2, title: "Elect-O-Mate",         content:  (
    
    <div>
      
      <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
        <img 
          src={EMWebsite} 
          alt="EM Website" 
          style={{ borderRadius: '15px', width: '50%' }} 
        />r
        <img 
          src={ScreenshotSO} 
          alt="Screenshot SO" 
          style={{ borderRadius: '15px', width: '50%' }} 
        />
      </div>
      <a href="https://elect-o-mate.eu/" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', marginLeft: '5px' }}>
        <span>Check out the website here ➔</span>
      </a>
              <div className=" w-1/2" style={{ wid: '50vh' }}>
              <Suspense fallback={<div>Loading PDF...</div>}>
                <SimplePDFViewer file={EM_PDF} />
              </Suspense>
              </div>
    </div>)
    
    , year: "2024", ActiveYN: "(Active)"},
    { id: 3, title: "Paper: Refusals", content: (
        <div>
           <div className=" w-1/2" style={{ wid: '50vh' }}>
              <Suspense fallback={<div>Loading PDF...</div>}>
                <SimplePDFViewer file={CannotOrShouldNotAutomatePDF} />
              </Suspense>
              </div>
        </div>
      ), year: "2024", ActiveYN: "(Active)"},
    { id: 4, title: "Paper: World3 Model Optimization via Genetic Algorithms", content: " This is my smester project with Prof. Dirk Helbing. We try to optimize the World3 model via Genetic Algorithm, by adding new feeedback loops that represent the potential of AI. AI will save us if,... You can find the code on Github. ", year: "2024", ActiveYN: "(Active)"},
    { id: 5, title: "Paper: Social Choice and Proportional Aggreagation Functions for IFT Datasets for LLM Alligment", content: " ", year: "2024", ActiveYN: "(Active)"},
    { id: 6, title: "Paper (published): Congestions and Spectral Transitions in Time-Lagged Correlations of Motorway Traffic", 
      content: (
        <div>
          <a href={"https://www.sciencedirect.com/science/article/pii/S0378437124004618"} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
            <span>Read Paper </span> ➔
          </a>
          <div className=" w-1/2" style={{ wid: '50vh' }}>
              <Suspense fallback={<div>Loading PDF...</div>}>
                <SimplePDFViewer file={MyPaperPDF} />
              </Suspense>
              </div>
                    </div>
      ), 
      year: "2024", 
      ActiveYN: "" 
    },
    { id: 7, title: "Alt Protein Project", content: " ", year: "2024", ActiveYN: "(Active)"},
    { id: 8, title: "Effective Altruism", content: " ", year: "2024", ActiveYN: ""},
    { id: 9, title: "AI Safety", content: " ", year: "2024", ActiveYN: ""},
    { id: 10, title: "ZuMUN Conference 2024", content: ` `, year: "2024", ActiveYN: ""  },
    { id: 11, title: "ETH Model United Nations", content: "", year: "2024", ActiveYN: "" },
    { id: 12, title: "TUM Carbon", content: "", year: "2024", ActiveYN: ""},
    { id: 13, title: "TUM Boring", content: "", year: "2024", ActiveYN: ""},
    { id: 14, title: "German Zero", content: "", year: "2024", ActiveYN: ""},
    { id: 15, title: "Volt", content: "", year: "2024", ActiveYN: "" }
  ];



const ContentQuestionsPrivate = () => (
  <div>
      <p>
          <strong style={{ fontSize: '1.5em' }}>Some questions to which I have clear answers:</strong>
      </p>
      <ul>
          <li>Why am I vegan? : why aren't you vegan? (read essay down below)</li>
          <li>What's the fairness of feminism?</li>
          <li>Why do I take long distance flights?</li>
          <li>What's my take on EA vs system change?</li>
          <li>How do I see and imagine gender to be in the future roles and do we get there?</li>
          <li>What is love?</li>
          <li>What is the meaning of life?</li>
          <li>What is the ultimate achievement I strive for?</li>
          <li>What do I expect of my children?</li>
          <li>Free will?</li>
          <li>What attrackts me in people?</li>


      </ul>
      
     
  </div>
);

 
  const [selectedId, setSelectedId] = useState(null);
  const [inputText, setInputText] = useState('');
  
  const [dropdownContentsPrivate, setDropdownContentsPrivate] = useState([
    { id: 1, title: "My pains from last year", content: "", year: "2024", ActiveYN: "" },
    { id: 2, title: "My fears for the future", content: "", year: "2024", ActiveYN: "" },
    { id: 3, title: "My brother", content: "", year: "2024", ActiveYN: "" },
    { id: 4, title: "My relationship with my mum", content: "", year: "2024", ActiveYN: "" },
    { id: 5, title: "My ideology about gender roles", content: "", year: "2024", ActiveYN: "" },
    { id: 6, title: "My childhood", content: "", year: "2024", ActiveYN: "" },
    { id: 7, title: "How I choose a partner", content: "", year: "2024", ActiveYN: "" },
    { id: 8, title: "My plan for the next 12 months", content: "", year: "2024", ActiveYN: "" },
    { id: 9, title: "My plan for the next 120 months", content: "", year: "2024", ActiveYN: "" },
    { id: 10, title: "Questions to ask me and yourself (not empty)", content:  <ContentQuestionsPrivate />, year: "2024", ActiveYN: "" },
    { id: 11, title: "My attachement stlye and childhood 'traumas' ", content:  "", year: "2024", ActiveYN: "" },
    { id: 12, title: "My sprituality and path to religion", content:  "", year: "2024", ActiveYN: "" }

  ]);

 

// Function to construct the new content for app.js
const updateAppJSContent = (newContent) => {
  return `import React from 'react';\n\nconst App = () => {\n  return (\n    <div>\n      <h1>Updated Content</h1>\n      <p>${newContent}</p>\n    </div>\n  );\n};\n\nexport default App;\n`;
};


// Function to handle adding content to the dropdown
const handleAddContent = () => {
  if (selectedId !== null && inputText.trim()) {
    const updatedContents = dropdownContentsPrivate.map((item) => {
      if (item.id === selectedId) {
        return {
          ...item,
          content: item.content + (item.content ? ', ' : '') + inputText,
        };
      }
      return item;
    });
    setDropdownContentsPrivate(updatedContents);
    setInputText(''); // Clear the input field after submission
  } else {
    alert('Please select an item and enter some content.');
  }
};
const API_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001/update-and-deploy'
    : 'http://localhost:3001/update-and-deploy';


const handleDeployContent = async () => {
  if (selectedId !== null) {
    const selectedContent = dropdownContentsPrivate.find(
      (item) => item.id === selectedId
    )?.content || '';
    const updatedContent =
      selectedContent + (selectedContent ? ', ' : '') + inputText;

    try {
      await axios.post(API_URL, {
        selectedId,
        updatedContent,
        title: dropdownContentsPrivate[selectedId - 1].title,
      });

      alert('Deployment triggered!');
    } catch (error) {
      console.error(error);
      alert('Failed to trigger deployment.');
    }
  } else {
    alert('Please select an item to update.');
  }
};





  const dropdownContentsProjectIdeas = [
    { id: 1, title: "Life Logger", content: "Ask what have I done today, Journal.Ai: YouTube video summaries at the end of everyday summaries my day. Maybe facing pointing out the most important thing that I have done on this day, it might help me to find the important things and see what the difference is between myself and an AI.", year: "2024", ActiveYN: "(Active)" },
    { id: 2, title: "Platform to Search for Initiatives at Your Uni or Events", content: " ", year: "2024", ActiveYN: "(Active)" },
    { id: 5, title: "Dating App via AI Matching", content: " ", year: "2024", ActiveYN: "(Active)" },
    { id: 6, title: "Finance4.0 Startup", content: " ", year: "2024", ActiveYN: "(Active)" },
    { id: 7, title: "LinkedIn Scraper and Ask Questions in 2nd Circle", content: "Content discussing LinkedIn scraping and querying in the 2nd circle.", year: "2024", ActiveYN: "(Active)" },
    { id: 8, title: "IDEA: Connect the Diplomatica Simulation with The New Commons Game or MUN or YouTube comments", content: "Have a look ???too aufwöndig.", year: "2024", ActiveYN: "(Active)" }
  ];

  const [isOpenPrivateContent, setIsOpenPrivateContent] = React.useState(false);

  const togglePrivateContent = () => {
    setIsOpenPrivateContent(!isOpenPrivateContent);
  };





  

  return (
    <ErrorBoundary>
    <div className="App bg-">
      <div className="pb-0 px-10 md:px-20">
        {/* Popup JSX */}
        {showPopup && (
          <div className="popup-overlay">
            <div className="popup-content">
              <h2>Enter Password</h2>
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Password"
                className="password-input"
              />
              <button onClick={handlePasswordSubmit}>Enter</button>
            </div>
          </div>
        )}

        <div className="pb-20 mt-32 lg:mt-52">
          <div className="rounded-full overflow-hidden w-32 h-32 mb-10">
            <img
              src="/Portrait_Gabor.jpeg"
              className="w-full h-full object-cover"
              alt="Portrait of Gabor Hollbeck"
              onClick={handleImageClick}
            />
          </div>
          <LandingPage />
        </div>

      

        <div className="pb-0 mt-0">
  <h1 className="text-sm mt-10 text-black md:text-lg lg:text-lg xl:text-lg text-left font-bold mb-4 md:mt-0">About Me: Gabor Hollbeck</h1>
  <p className="text-sm text-black md:text-lg lg:text-lg xl:text-lg text-left">
    M.Sc. Physics @ETH Zürich, 
    <span 
      onClick={() => {
        const section = document.getElementById('projects-section');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          setOpenIndexProjects(1); // Open the dropdown for Diplomatica
        }
      }} 
      style={{ cursor: 'pointer', color: 'orange', fontWeight: 'bold' }}
    >
     <span></span> Diplomatica
    </span>
    
    , <span></span>
    <span 
      onClick={() => {
        const section = document.getElementById('projects-section');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          setOpenIndexProjects(2); // Open the dropdown for Elect-O-Mate
        }
      }} 
      style={{ cursor: 'pointer', color: 'orange', fontWeight: 'bold' }}
    >
      Elect-O-Mate <span></span>
    </span> 
    
    and <span></span>
    <span 
      onClick={() => {
        const section = document.getElementById('projects-section');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          setOpenIndexProjects(6); // Open the dropdown for Alt Protein Project
        }
      }} 
      style={{ cursor: 'pointer', color: 'orange', fontWeight: 'bold' }}
    >
      Alt Protein Project 
    </span> 
    

    
    <span></span>  and <span></span>
    <span 
      onClick={() => {
        const section = document.getElementById('projects-section');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          setOpenIndexEssays(5); // Open the dropdown for Complex Social Systems
        }
      }} 
      style={{ cursor: 'pointer', color: 'orange', fontWeight: 'bold' }}
    >
       Complex Social Systems
    </span>
    
    . Passionate about AI4Good, Sustainability, Longtermism, Social Entrepreneurship, Mindfulness and Rationality.
  </p>
</div>


   {/* PRIVATE content */}
      {showPrivateContent && (
        <div className="rainbow-hint" style={{ backgroundColor: 'rgb(231, 231, 231)', padding: '30px', marginTop: '40px' }}>
          <div className="text-md" style={{ textAlign: 'left' }}>
            <p><strong>This is where private content will be displayed</strong></p>
            <br /> <br />
            {dropdownContentsPrivate.map(({ id, title, content, year, ActiveYN }) => (
              <div key={id} className={`${isOpenPrivateContent === id ? 'mb-6 mt-2' : 'mb-0'}`}>
                <DropdownTextBox
                  title={`${title}  `}
                  activeYN={`${ActiveYN}`}
                  isOpen={isOpenPrivateContent === id}
                  toggleDropdown={() => setIsOpenPrivateContent(isOpenPrivateContent === id ? null : id)}
                >
                  {content}
                </DropdownTextBox>
              </div>
            ))}

            {/* Text Field and Button */}
            {/* <div style={{ marginTop: '20px' }}>
              <select onChange={(e) => setSelectedId(Number(e.target.value))} value={selectedId || ''}>
                <option value="" disabled>Select an option</option>
                {dropdownContentsPrivate.map(({ id, title }) => (
                  <option key={id} value={id}>{title}</option>
                ))}
              </select>
              
              <input
                type="text"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder="Enter your content here"
                style={{ marginLeft: '10px', padding: '5px' }}
              />
              
              <button onClick={handleAddContent} style={{ marginLeft: '10px', padding: '5px' }}>
                Add Content
              </button>

              <button onClick={handleDeployContent} style={{ marginLeft: '10px', padding: '5px' }}>
                Deploy to GitHub
              </button>
            </div> */}
          </div>
        </div>
      )}



        <div className="flex flex-col items-start mt-10">
    {Array.from({ length: 30 }).map((_, index) => (
      <div key={index} className={`transform transition-transform duration-300 rotate-90`}>
        <svg
          className="w-5 h-5 text-gray-600 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div>
    ))}
</div>



        <div className="pb-0 mt-8">
        <div className="pb-0 mt-0">
            <h1 className="text-4xl font-bold mb-10">Essays</h1>
        </div>


          <Essays />
        </div>

        




{/* 
        <div className="pb-1 mt-0">
          <h1 className="text-lg font-bold mb-4">Essays</h1>
        </div>

        <div>
          {dropdownContentsEssays.map(({ id, title, content }) => (
            <div key={id} className={`${openIndexEssays === id ? 'mb-6 mt-2' : 'mb-0'}`}>
              <DropdownTextBox
                title={` ${title}`}
                isOpen={openIndexEssays === id}
                toggleDropdown={() => handleToggleEssays(id)}
              >

            <div className="markdown-content">
              <ReactMarkdown>{content}</ReactMarkdown>
            </div>

            </DropdownTextBox>
            </div>
          ))}
        </div> */}







<div id="projects-section">
        <div className="pb-1 mt-2">
          <h1 className="text-4xl font-bold mb-5">Projects / Publications</h1>
          <h1 className="text-base mb-8">Just a list of in what, when, how, why I have been involved + what learnings I had</h1>
        
        </div>

        
          {dropdownContentsProjects.map(({ id, title, content, year, ActiveYN }) => (
            <div key={id} className={`${openIndexProjects === id ? 'mb-6 mt-2' : 'mb-0'}`}>
              <DropdownTextBox
                title={`${title}  `}
                activeYN = {`${ActiveYN}`}  
                isOpen={openIndexProjects === id}
                toggleDropdown={() => handleToggleProjects(id)}
              >
                {content}
                
              </DropdownTextBox>
            </div>
          ))}
        </div>





        <div className="pb-1 mt-10 ">
          <h1 className="text-4xl font-bold mb-5">Project Ideas <span className="text-sm">(but no time for me to pursue them)</span></h1>
          <h1 className="text-base mb-8">I want to provide a list of Ideas for Projectds, Startups or any endeavours, that I think have some value for the world or to yourself. I dont have enough time to make them happen, so feel free to be inspired and ask me if you want to have more insights or some supervision. Maybe Ill offer some fincacial insenties between 100-1000$.</h1>
        </div>

        <div>
          {dropdownContentsProjectIdeas.map(({ id, title, content }) => (
            <div key={id} className={`${openIndexProjectIdeas === id ? 'mb-6 mt-2' : 'mb-0'}`}>
              <DropdownTextBox
                title={` ${title}`}
                isOpen={openIndexProjectIdeas === id}
                toggleDropdown={() => handleToggleProjectIdeas(id)}
              >
                {content}
              </DropdownTextBox>
            </div>
          ))}
        </div>


















        <div className="pb-1 mt-10">
          <h1 className="text-4xl font-bold  mb-5">Photo- and Videography</h1>
          <h1 className="text-base mb-8">Here is a short list of the my "professional" work in this field</h1>
        </div>

        {/* <div className="flex flex-wrap justify-center mb-6">
          {ListVideosPhotos.map(({ id }) => (
            <div key={id} className="m-2">
              <a href={`https://www.youtube.com/watch?v=${id}`} target="_blank" rel="noopener noreferrer">
                <YouTubeEmbed videoId={id} playing={false} />
              </a>
              <img src={`https://img.youtube.com/vi/${id}/0.jpg`} alt={`Thumbnail for video ${id}`} className="mt-2" />
            </div>
          ))}
        </div> */}
{/* 
<div className="video-grid mb-20">
  {ListVideosPhotos.map(({ id, title }) => (
    <div key={id} className="video-item">
      <YouTubeEmbed videoId={id} playing={false} />
      <p>{title}</p>
    </div>
  ))}
</div> */}


      


        <div>
          {ListVideosPhotos.map(({ id, title, content }) => (
            <div key={id} className={`${openIndexListVideosPhotos === id ? 'mb-6 mt-2' : 'mb-0'}`}>
            <DropdownTextBox
              title={` ${title}`}
              isOpen={openIndexListVideosPhotos === id}
              toggleDropdown={() => handleToggleListVideosPhotos(id)}
            >
              {content}
            </DropdownTextBox>
          </div>
          ))}
        </div>



        <div className="pb-1 mt-10">
          <h1 className="text-2xl font-bold  mb-5 ">Photography</h1>
        </div>

        <div className="flex flex-col justify-center items-center w-full">
       
          <div className="w-full flex justify-center items-center">
            <div className="md:w-[66%] w-[100%] ">
              <ImageGrid />
            </div>
          </div>

         
        </div>

        




      </div>




      <div className=" mt-10  ">
        <div className="flex">
          {/* All your content here */}
        </div>
        <Footer />
      </div>
    </div>
        </ErrorBoundary>

  );
}

export default App;









const ImageGrid = () => {
  const [images1, setImages1] = useState([]);
  const [images2, setImages2] = useState([]);
  const [images3, setImages3] = useState([]);
  const [images4, setImages4] = useState([]);
  
  // State for modal
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const importAllImages = () => {
      const context = require.context(
        './pictures/Photography',
        false,
        /\.(png|jpe?g|jpeg|svg|webp|JPG|JPEG|PNG|SVG|WEBP|gif|GIF|bmp|BMP|tiff|TIFF|r)$/
      );
      const imageList = context.keys().map(context);
      const totalImages = imageList.length;
      const baseLength = Math.floor(totalImages / 4);
      const remainder = totalImages % 4;

      setImages1(imageList.slice(0, baseLength + (remainder > 0 ? 1 : 0)));
      setImages2(imageList.slice(
        baseLength + (remainder > 0 ? 1 : 0),
        2 * baseLength + (remainder > 1 ? 1 : 0)
      ));
      setImages3(imageList.slice(
        2 * baseLength + (remainder > 1 ? 1 : 0),
        3 * baseLength + (remainder > 2 ? 1 : 0)
      ));
      setImages4(imageList.slice(3 * baseLength + (remainder > 2 ? 1 : 0)));
    };

    importAllImages();
  }, []);

  // Handler to open modal with selected image
  const handleImageClick = (src) => {
    setSelectedImage(src);
  };

  // Handler to close modal
  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="">
      {/* Image Grid */}
      <div className="grid md:gap-4 gap-1 grid-cols-4">
        {/* Column 1 */}
        <div className="grid md:gap-4 gap-1 auto-rows-auto">
          <div className="object-cover object-center h-auto max-w-full">
            {images1.map((src, index) => (
              <img
                key={index}
                className="md:mt-4 mt-1 rounded-lg cursor-pointer hover:opacity-70 hover:scale-110 transition-transform duration-300"
                src={src}
                alt={`Gallery Image ${index + 1}`}
                onClick={() => handleImageClick(src)}
              />
            ))}
          </div>
        </div>

        {/* Column 2 */}
        <div className="grid md:gap-4 gap-1 auto-rows-auto">
          <div className="object-cover object-center h-auto max-w-full">
            {images2.map((src, index) => (
              <img
                key={index}
                className="md:mt-4 mt-1 rounded-lg cursor-pointer hover:opacity-70 hover:scale-110 transition-transform duration-300"
                src={src}
                alt={`Gallery Image ${index + 1}`}
                onClick={() => handleImageClick(src)}
              />
            ))}
          </div>
        </div>

        {/* Column 3 */}
        <div className="grid md:gap-4 gap-1 auto-rows-auto">
          <div className="object-cover object-center h-auto max-w-full">
            {images3.map((src, index) => (
              <img
                key={index}
                className="md:mt-4 mt-1 rounded-lg cursor-pointer hover:opacity-70 hover:scale-110 transition-transform duration-300"
                src={src}
                alt={`Gallery Image ${index + 1}`}
                onClick={() => handleImageClick(src)}
              />
            ))}
          </div>
        </div>

        {/* Column 4 */}
        <div className="grid md:gap-4 gap-1 auto-rows-auto">
          <div className="object-cover object-center h-auto max-w-full">
            {images4.map((src, index) => (
              <img
                key={index}
                className="md:mt-4 mt-1 rounded-lg cursor-pointer hover:opacity-70 hover:scale-110 transition-transform duration-300"
                src={src}
                alt={`Gallery Image ${index + 1}`}
                onClick={() => handleImageClick(src)}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50 transition-opacity duration-300 ease-in-out">
          <div className="relative p-4 transform transition-transform duration-300 ease-in-out scale-100">
            <img
              src={selectedImage}
              alt="Selected"
              className="max-h-[80vh] max-w-[80vw] rounded-lg"
            />
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 bg-white bg-transparent rounded-full p-2 hover:bg-opacity-100 transition"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-gray-500 hover:text-white hover:scale-[1.3] transition-transform duration-300"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export { ImageGrid };
